import { useStaticQuery, graphql } from "gatsby"

export const useContenfulSocialNetworks = countrie => {
  const {
    allContentfulRedesSociales: { nodes: contenfulSocialNetworks },
  } = useStaticQuery(graphql`
    {
      allContentfulRedesSociales {
        nodes {
          countries {
            key
          }
          facebook_url
          instagram_url
          linkdin_url
        }
      }
    }
  `)

  if(!countrie)
    return contenfulSocialNetworks

  let transform

  contenfulSocialNetworks.forEach(item => {
    const isCountrie = item.countries.find(
      _countrie => _countrie.key === countrie
    )
    if (!countrie || isCountrie) transform = item
  })

  return transform
}
